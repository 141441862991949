<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">



      <a-form-model-item prop="theme" >
        <span slot="label" >
        <a-tooltip>
        {{$t('直播主题')}}<template slot="title">{{$t('直播主题')}}</template>
        </a-tooltip>
        </span>
        <a-input v-model="form.theme" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播主题')"/>
      </a-form-model-item>


      <a-form-model-item prop="liveScreen" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播画面')}}<template slot="title">{{$t('直播画面')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('通用.输入.请选择')+$t('直播画面')" v-model="form.liveScreen" allowClear>
          <a-select-option  value="1">{{$t('竖屏')}}</a-select-option>
          <a-select-option  value="2">{{$t('横屏')}}</a-select-option>
        </a-select>
      </a-form-model-item>


      <!--<a-form-model-item prop="thumb" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播封面')}}<template slot="title">{{$t('直播封面')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.thumb" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播封面')" />-->
      <!--</a-form-model-item>-->





      <a-form-model-item prop="liveAddress" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播地址')}}<template slot="title">{{$t('直播地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.liveAddress" :placeholder="$t('通用.输入.请输入')+$t('直播地址')" />
      </a-form-model-item>
      <a-form-model-item prop="preOpenTime" >
              <span slot="label" >
                <a-tooltip>
                  {{$t('直播预计开启时间')}}<template slot="title">{{$t('直播预计开启时间')}}</template>
                </a-tooltip>
              </span>
        <a-date-picker style="width: 100%" v-model="form.preOpenTime" value-format="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="this.$t('直播封面')" prop="thumb">
        <a-upload
          name="thumb"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.thumb"
            :src="form.thumb"
            alt="picture"
            style="height: 100px; width: 100px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 100 x 100</span>
      </a-form-model-item>




      <!--选择商品-->
      <!--<a-form-model-item :label="$t('通用.文本.选择商品')" prop="goodsId">-->
        <!--<a-button type="dashed" @click="openProdSelect" danger>-->
          <!--<span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>-->
        <!--</a-button>-->
      <!--</a-form-model-item>-->
      <!-- 数据展示 -->
      <!--<a-table-->
        <!--:loading="goodsLoading"-->
        <!--:scroll="{ x: '160%' }"-->
        <!--rowKey="id"-->
        <!--:size="tableSize"-->
        <!--:columns="goodsColumns"-->
        <!--:data-source="form.goodsList"-->
        <!--:pagination="false"-->
        <!--:bordered="tableBordered">-->
        <!--<span slot="createTime" slot-scope="text, record">-->
          <!--{{ parseTime(record.createTime) }}-->
        <!--</span>-->
        <!--<template slot="picture" slot-scope="text, record">-->
          <!--<div>-->
            <!--<img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>-->
          <!--</div>-->
        <!--</template>-->

        <!--<span slot="categoryId" slot-scope="text, record">-->
            <!--{{getCategoryNameById(record.categoryId)}}-->
        <!--</span>-->
        <!--<span slot="brandId" slot-scope="text, record">-->
             <!--{{getBrandNameById(record.brandId)}}-->
          <!--</span>-->
        <!--<span slot="status" slot-scope="text, record">-->
           <!--<custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>-->
          <!--</span>-->
      <!--</a-table>-->

      <!--选择商品-->

      <a-form-model-item prop="allowUserId" >
            <span slot="label" >
              <a-tooltip>
                {{$t('允许开播用户')}}<template slot="title">{{$t('允许开播用户')}}</template>
              </a-tooltip>
            </span>
        <a-select v-model="form.allowUserId" placeholder="请选择用户">
          <a-select-option v-for="(item, index) in shopUserList" :value="item.pveInviteUserId" :key="index">
            {{ item.nickname }}
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <!--<a-form-model-item prop="signInter" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('签到间隔时长')}}<template slot="title">{{$t('签到间隔时长')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input-number v-model="form.signInter" :placeholder="$t('通用.输入.请输入')+$t('签到间隔时长')"/>-->
      <!--</a-form-model-item>-->

      <!--<a-form-model-item prop="signScore" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播签到获取积分')}}<template slot="title">{{$t('直播签到获取积分')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.signScore" @change="limitPrice()" :placeholder="$t('通用.输入.请输入')+$t('直播签到获取积分')"/>-->
      <!--</a-form-model-item>-->

      <a-form-model-item prop="seekInter" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播间观看间隔时长')}}<template slot="title">{{$t('直播间观看间隔时长')}}</template>
          </a-tooltip>
        </span>
        <a-input-number v-model="form.seekInter" :placeholder="$t('通用.输入.请输入')+$t('直播间观看间隔时长')"/>
      </a-form-model-item>

      <!--<a-form-model-item prop="signShowTime" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播签到显示时长(分钟)')}}<template slot="title">{{$t('直播签到显示时长(分钟)')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input-number v-model="form.signShowTime" :placeholder="$t('通用.输入.请输入')+$t('直播签到显示时长(分钟)')"/>-->
      <!--</a-form-model-item>-->
      <a-form-model-item prop="seekScore" >
        <span slot="label" >
          <a-tooltip>
            {{$t('直播间观看获取积分')}}<template slot="title">{{$t('直播间观看获取积分')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.seekScore" @change="limitPrice1()" :placeholder="$t('通用.输入.请输入')+$t('直播间观看获取积分')"/>
      </a-form-model-item>

      <a-form-model-item prop="bitRate" >
        <span slot="label" >
          <a-tooltip>
            {{$t('码率')}}<template slot="title">{{$t('码率')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.bitRate" @change="limitbitRate()" :placeholder="$t('通用.输入.请输入')+$t('码率')"/>
      </a-form-model-item>

      <a-form-model-item prop="userShare" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否允许普通用户分享')}}<template slot="title">{{$t('是否允许普通用户分享')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('通用.输入.请选择')+$t('是否允许普通用户分享')" v-model="form.userShare" allowClear>
          <a-select-option  :value="0">{{$t('否')}}</a-select-option>
          <a-select-option  :value="1">{{$t('是')}}</a-select-option>
        </a-select>
      </a-form-model-item>

<!--      <a-form-model-item prop="imGroupId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('腾讯IM群ID(发起直播单独创建一个群)')}}<template slot="title">{{$t('腾讯IM群ID(发起直播单独创建一个群)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.imGroupId" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('腾讯IM群ID(发起直播单独创建一个群)')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="userId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播用户id')}}<template slot="title">{{$t('直播用户id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('直播用户id')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="shopId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播商户id')}}<template slot="title">{{$t('直播商户id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('直播商户id')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="shopName" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播人昵称')}}<template slot="title">{{$t('直播人昵称')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.shopName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播人昵称')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="shopLogo" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播人头像')}}<template slot="title">{{$t('直播人头像')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.shopLogo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播人头像')" />-->
<!--      </a-form-model-item>-->


<!--      <a-form-model-item prop="intro" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播主题介绍')}}<template slot="title">{{$t('直播主题介绍')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.intro" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="liveName" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播间名称(推流名称)')}}<template slot="title">{{$t('直播间名称(推流名称)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.liveName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('直播间名称(推流名称)')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="openTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播开启时间')}}<template slot="title">{{$t('直播开启时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.openTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="closeTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播关闭时间(前端点击关播时间,或者定时任务扫描到没有推流了)')}}<template slot="title">{{$t('直播关闭时间(前端点击关播时间,或者定时任务扫描到没有推流了)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.closeTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="expiredTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('推流过期时间')}}<template slot="title">{{$t('推流过期时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.expiredTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="effective" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播有效时长(单位:小时)')}}<template slot="title">{{$t('直播有效时长(单位:小时)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.effective"  :placeholder="$t('通用.输入.请输入')+$t('直播有效时长(单位:小时)')" />-->
<!--      </a-form-model-item>-->
      <!--<a-form-model-item prop="duration" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播时长(秒)')}}<template slot="title">{{$t('直播时长(秒)')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input-number v-model="form.duration"  :placeholder="$t('通用.输入.请输入')+$t('直播时长')" disabled="true"/>-->
      <!--</a-form-model-item>-->
<!--      <a-form-model-item prop="pushUrl" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('推流地址')}}<template slot="title">{{$t('推流地址')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.pushUrl" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="pullUrl" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('拉流地址')}}<template slot="title">{{$t('拉流地址')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.pullUrl" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <!--<a-form-model-item prop="chatNum" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播互动人数(峰值)')}}<template slot="title">{{$t('直播互动人数(峰值)')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input-number v-model="form.chatNum"  :placeholder="$t('通用.输入.请输入')+$t('直播互动人数(峰值)')" disabled="true"/>-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="totalMoney" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('直播累计收益 ')}}<template slot="title">{{$t('直播累计收益 ')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input-number v-model="form.totalMoney"  :placeholder="$t('通用.输入.请输入')+$t('直播累计收益 ')" disabled="true"/>-->
      <!--</a-form-model-item>-->
<!--      <a-form-model-item prop="status" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播状态 1:推流地址生成(未开播) 2:已开播 3:直播结束')}}<template slot="title">{{$t('直播状态 1:推流地址生成(未开播) 2:已开播 3:直播结束')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="cutInfo" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('断流信息(直播断流总信息)')}}<template slot="title">{{$t('断流信息(直播断流总信息)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.cutInfo" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="isErrorClose" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')}}<template slot="title">{{$t('是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.isErrorClose"  :placeholder="$t('通用.输入.请输入')+$t('是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="errorCloseTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('异常时间')}}<template slot="title">{{$t('异常时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.errorCloseTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="classifyId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播分类,live_live_classify')}}<template slot="title">{{$t('直播分类,live_live_classify')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.classifyId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播分类,live_live_classify')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="liveType" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播类型 (1:语音直播,2:视频直播)')}}<template slot="title">{{$t('直播类型 (1:语音直播,2:视频直播)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item prop="liveCityName" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('直播城市')}}<template slot="title">{{$t('直播城市')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.liveCityName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('直播城市')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="redPacketExpiredTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('红包过期时间')}}<template slot="title">{{$t('红包过期时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.redPacketExpiredTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="redPacketBeginTime" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('红包开始领取时间')}}<template slot="title">{{$t('红包开始领取时间')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-date-picker style="width: 100%" v-model="form.redPacketBeginTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="remark" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('备注')}}<template slot="title">{{$t('备注')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <!--<a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">-->
        <!--<img alt="example" style="width: 100%" :src="previewUrl" />-->
      <!--</a-modal>-->
      <!--<a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">-->
        <!--<video style="width: 100%" :src="previewUrl" ref="myVideo"-->
               <!--:poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>-->
      <!--</a-modal>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>


      <!--选择商品-->
      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
      <!--选择商品-->

    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLive, addLive, updateLive,shopUserList } from '@/api/live/live'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";

//选择商品
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectFormGoods";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";
//选择商品

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    //选择商品
    RadioGoodsSelectForm
    //选择商品
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',

      //选择商品
      categoryList:[],
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
      ],
      //选择商品

      // 表单参数
      form: {
        //选择商品
        goodsList: [],
        //选择商品

        id: null,

        imGroupId: null,

        userId: null,

        shopId: null,

        shopName: null,

        shopLogo: null,

        thumb: null,

        theme: null,

        intro: null,

        liveName: null,

        openTime: null,

        closeTime: null,

        expiredTime: null,

        effective: null,

        duration: null,

        pushUrl: null,

        pullUrl: null,

        chatNum: null,

        totalMoney: null,

        status: 0,

        cutInfo: null,

        isErrorClose: null,

        errorCloseTime: null,

        classifyId: null,

        liveType: null,

        liveAddress: null,
        preOpenTime: null,

        liveCityName: null,

        redPacketExpiredTime: null,

        redPacketBeginTime: null,

        createTime: null,

        remark: null,
        signInter:null,
        signScore:null,
        seekInter:null,
        seekScore:null,
        userShare:null,
        signShowTime:null,
        signNum:null,
        liveScreen:null,
        bitRate:null,
        flow:null,
        allowUserId:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        imGroupId: [
          { required: true, message: this.$t('腾讯IM群ID(发起直播单独创建一个群)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        theme: [
          { required: true, message: this.$t('直播主题')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isErrorClose: [
          { required: true, message: this.$t('是否直播流异常,1,异常 0,正常,腾讯云直播流异常时写入')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        liveScreen:[
          { required: true, message: this.$t('直播主题')+this.$t('通用.输入.请选择'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    //选择商品
    this.getCateList();
    this.getBrandList();
    //选择商品

    this.getShopUserList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        //选择商品
        goodsList: [],
        //选择商品
        id: null,
        imGroupId: null,
        userId: null,
        shopId: null,
        shopName: null,
        shopLogo: null,
        thumb: null,
        theme: null,
        intro: null,
        liveName: null,
        openTime: null,
        closeTime: null,
        expiredTime: null,
        effective: null,
        duration: null,
        pushUrl: null,
        pullUrl: null,
        chatNum: null,
        totalMoney: null,
        status: 0,
        cutInfo: null,
        isErrorClose: null,
        errorCloseTime: null,
        classifyId: null,
        liveType: null,
        liveAddress: null,
        preOpenTime: null,
        liveCityName: null,
        redPacketExpiredTime: null,
        redPacketBeginTime: null,
        createTime: null,
        remark: null,
        signInter:null,
        signScore:null,
        seekInter:null,
        seekScore:null,
        userShare:null,
        signShowTime:null,
        signNum:null,
        liveScreen:null,
        bitRate:null,
        flow:null,
        allowUserId:null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLive({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.signScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.signScore = val
    },
    limitPrice1() {
      // console.log(i);
      let val = this.form.seekScore.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.seekScore = val
    },

    limitbitRate() {
      // console.log(i);
      let val = this.form.bitRate.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.bitRate = val
    },

    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'thumb'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
        _this.$set(_this.form, 'thumb', res)
      _this.$message.success(this.$t('通用.文本.上传成功'),)
    })
    })
    },
    coverDetailImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'live'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
        _this.$set(_this.form, 'detailImg', res)
      _this.$message.success(this.$t('通用.文本.上传成功'),)
    })
    })
    },
    // oss 上传结束

    //选择商品
    indexGoodsModalSelect(records) {
      this.form.goodsId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    //选择商品

    //选择商品
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },

    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
      const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
      cate.children = this.handleTree(response.data, 'id','pid')
      this.cateOptions.push(cate)
    })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
    })
    },
    //选择商品

    //查询店铺用户列表
    getShopUserList() {
      shopUserList().then(response => {
      this.shopUserList = response.data
    })
    },


  }
}
</script>
