import request from '@/utils/request'


// 查询直播列表
export function listLive(query) {
  return request({
    url: '/live/live/list',
    method: 'get',
    params: query
  })
}

// 查询直播分页
export function pageLive(query) {
  return request({
    url: '/live/live/page',
    method: 'get',
    params: query
  })
}

// 查询直播详细
export function getLive(data) {
  return request({
    url: '/live/live/detail',
    method: 'get',
    params: data
  })
}

// 新增直播
export function addLive(data) {
  return request({
    url: '/live/live/add',
    method: 'post',
    data: data
  })
}

// 修改直播
export function updateLive(data) {
  return request({
    url: '/live/live/edit',
    method: 'post',
    data: data
  })
}

// 关闭直播
export function endLive(data) {
  return request({
    url: '/live/live/endLive',
    method: 'post',
    data: data
  })
}
// 关闭直播
export function startLive(data) {
  return request({
    url: '/live/live/startLive',
    method: 'post',
    data: data
  })
}

// 删除直播
export function delLive(data) {
  return request({
    url: '/live/live/delete',
    method: 'post',
    data: data
  })
}

// 查询直播统计信息
export function getLiveData(data) {
  return request({
    url: '/live/live/getLiveData',
    method: 'get',
    params: data
  })
}

// 查询直播在线人数统计
export function getMembersList(data) {
  return request({
    url: '/live/live/getMembersList',
    method: 'get',
    params: data
  })
}

// 查询店铺用户列表
export function shopUserList(data) {
  return request({
    url: '/live/live/shopUserList',
    method: 'get',
    data: data
  })
}
